<template>
  <BasePage :bannerUrl="banner" subTilte="南通市海衡招标有限公司欢迎您" content="规范服务 / 阳光清廉 / 示范引领 / 降本增效" :hideBanner="true" :show="true" :imageUrl="imageUrl" :title="title" :contentX="contentX">
    <div class="Financial_wrap">
      <div class="Financial_subtitle">业务内容</div>
      <div class="Financial_line">
        <div class="Financial_line_left"></div>
        <div class="Financial_line_right"></div>
      </div>
      <div style="width: 87%;display: flex;justify-content: space-between;margin: 0 auto;">
        <div style="display: flex;flex-direction: column;justify-content: space-between;">
          <div class="box1">
            <div style="display: flex;align-items: center;">
              <div class="box-number">01</div>
              <div class="box-title">服务范围</div>
            </div>
            <div class="content-text">
              海衡平台会员单位
            </div>
          </div>
          <div class="box1">
            <div style="display: flex;align-items: center;">
              <div class="box-number">02</div>
              <div class="box-title">融资特点</div>
            </div>
            <div class="content-text">
              贷款渠道安全可靠，利率低、全方位贷款服务
            </div>
          </div>
          <div class="box1">
            <div style="display: flex;align-items: center;">
              <div class="box-number">03</div>
              <div class="box-title">融资模式</div>
            </div>
            <div class="content-text">
              基于国企采购合同，以合同下国企回款作为还款来源的综合授信，银行等金融机构为供应商提供的专项贷款
            </div>
          </div>
          <div class="box1">
            <div style="display: flex;align-items: center;">
              <div class="box-number">04</div>
              <div class="box-title">融资渠道</div>
            </div>
            <div class="content-text">
              平台申请
            </div>
          </div>
        </div>
        <div style="display: flex;align-items: center;">
          <img src="@/assets/img3.png" alt="" style="width: 400px;height: 356.44px;">
        </div>
      </div>
    </div>
    <div class="Financial_wrap" style="padding-top: 20px">
      <div class="Financial_subtitle">业务功能</div>
      <div class="Financial_line">
        <div class="Financial_line_left"></div>
        <div class="Financial_line_right"></div>
      </div>
      <div style="width: 87%;display: flex;justify-content: space-between;margin: 0 auto;">
        <div style="display: flex;align-items: center;">
          <img src="@/assets/img4.png" alt="" style="width: 420px;height: 357.13px;">
        </div>
        <div style="display: flex;flex-direction: column;justify-content: space-between;">
          <div class="bgc-box">为供应链打造一个良好的生态平衡体系</div>
          <div class="bgc-box">为供应链企业提供应收账款融资服务，加速企业资金融通</div>
          <div class="bgc-box">为金融机构提供企业贸易真实性监控等功能降低资金风险</div>
        </div>
      </div>
    </div>
    <div class="Financial_wrap" style="padding-top: 20px">
      <div class="Financial_subtitle">业务模型</div>
      <div class="Financial_line">
        <div class="Financial_line_left"></div>
        <div class="Financial_line_right"></div>
      </div>
      <div>
        <img src="@/assets/img5.png" alt="" style="width: 800px;height: 554.99px;">
      </div>
    </div>
  </BasePage>
</template>

<script>
import imgData from "@/data";
import { reactive, ref } from "vue";
import BasePage from "@/components/BasePage/BasePage.vue";
export default {
  components: { BasePage },
  setup() {
    const data = reactive({
      ...imgData.FinancialImg,
    });
    const imageUrl = ref(require('@/assets/w1.png'));
    const title = ref('金融服务');
    const contentX = ref('连接供应商与金融机构的桥梁<br>为供应链中小微企业提供融资贷款服务');
    return {
      ...data,
      imageUrl,
      title,
      contentX
    };
  },
};
</script>

<style scoped>
@import url("./Financial.css");
</style>

